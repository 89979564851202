import React, { useState, useEffect, useRef } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import instaimg from "../../assets/Home/insta.svg";
import linkedimg from "../../assets/Home/link.svg";
import faceimg from "../../assets/Home/face.svg";
import logo from "../../assets/logo.png";

function Footer({ onLinkHover, onLinkLeave }) {
  return (
    <div className="main-footer">
      <hr />

      <div className="container main-foot">
        <div className="row">
          {/* Column1 */}
          <div className="col-lg-4 address-sec">
            <div className="address-foot">
              <img src={logo} alt="saaa-logo" />
              <h4>SAAA Consultants Pvt. Ltd</h4>
              <h5>Mumbai</h5>
            </div>
            <div className="address-foot">
              <h5>Aurangabad</h5>
            </div>
            <div className="address-foot">
              <h5>Singapore</h5>
            </div>
          </div>
          <div className="col-lg-3 qls">
            <div className="quick-links">
              <h6>Quick Links</h6>

              <Link
                to="/home"
                onMouseEnter={onLinkHover}
                onMouseLeave={onLinkLeave}
              >
                <h4>Home</h4>
              </Link>
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {/* <div> */}
                <h4>Services</h4>
              </a>
              <div
                className="dropdown-menu drop-full"
                aria-labelledby="navbarDropdown"
              >
                <Link
                  className="dropdown-item drop-1"
                  to="/services/digital-marketing"
                >
                  Digital Marketing
                </Link>
                <Link
                  className="dropdown-item drop-1"
                  to="/services/web-development"
                >
                  Web Development
                </Link>
                <a
                  className="dropdown-item drop-1"
                  href="/services/designing-branding"
                >
                  Designing / Branding
                </a>
                <a className="dropdown-item drop-1" href="/services/ui-ux">
                  UI/UX
                </a>
                {/* <a className="dropdown-item drop-1" href="/services/financial-advisory">
                      Financial Advisory Services
                    </a> */}
                <a
                  className="dropdown-item drop-1"
                  href="/services/legal-compliance"
                >
                  Legal Compliance
                </a>
              </div>
              {/* </div> */}
              <Link to="/about">
                <h4>About us</h4>
              </Link>
              <Link to="/our-influencers">
                <h4>Our Influencers</h4>
              </Link>
              <Link to="/our-team">
                <h4>Our Team</h4>
              </Link>
              <Link to="/case-studies">
                <h4>Case Studies</h4>
              </Link>
              <Link to="/contact">
                <h4>Contact</h4>
              </Link>
              {/* <Link to="/Policy">
                <h4>Policy</h4>
              </Link> */}
              {/* <Link to="/Teammembers">
                <h4>Team members</h4>
              </Link> */}
            </div>
          </div>
          {/* Column1 4 */}
          <div className="col-lg-3 address-foot-2">
            <div>
              <h4>Contact Info</h4>
              <p className="list-unstyled address-in-footer">
                <li>
                  <p>
                    A wing, 11th Floor, 1104 Kanakia Wall Street, Andheri East,
                    Mumbai, India
                  </p>
                </li>
              </p>
            </div>
            <div>
              <p className="list-unstyled address-in-footer">
                <li>
                  <p>+91 7977895134</p>
                </li>
              </p>
            </div>
            <div>
              <p className="list-unstyled address-in-footer">
                <li>
                  <p>info@saaaconsultants.com</p>
                </li>
              </p>
            </div>
          </div>
          {/* Column2 */}

          {/* Column3 */}
          <div className="col-lg-2 third-text ">
            <h6>Social Links</h6>
            <div className="ss-links">
              <ui className="list-styled">
                <li>
                  <Link
                    to="https://www.linkedin.com/company/saaa-consultants/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={linkedimg}
                      className="link-hover"
                      alt="linkdin-icon"
                    />
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/saaaconsultants/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={instaimg}
                      className="link-hover"
                      alt="instagram-icon"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61552306096386"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={faceimg}
                      className="link-hover"
                      alt="facebook-icon"
                    />
                  </a>
                </li>
              </ui>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
