import React from "react";
import { ClipLoader } from "react-spinners";
import "./about/DualSlider.css";

const LoadingSpinner = () => {
  return (
    <div className="loading-container">
      <ClipLoader color="#00BFFF" size={50} />
    </div>
  );
};

export default LoadingSpinner;
