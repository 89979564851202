import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./component/Navbar/Navbar";
import Footer from "./component/footer/footer";
import LoadingSpinner from "./component/LoadingSpinner";
import "./App.css";

const Home = lazy(() => import("./pages/home/Home"));
const ContactForm = lazy(() => import("./pages/contact/Contact"));
const About = lazy(() => import("./pages/about/About"));
const Teammembers = lazy(() => import("./pages/Teammembers/Teammembers"));
const Graphic = lazy(() => import("./component/plans/Graphic"));
const Uiux = lazy(() => import("./component/plans/Uiux"));
const SmmPlans = lazy(() => import("./component/plans/SmmPlans"));
const WebPlans = lazy(() => import("./component/plans/WebPlans"));
const LegalCompliancePlans = lazy(() => import("./component/plans/Legalplan"));
const SEOCampaign = lazy(() => import("./pages/seocampaign/seocampaign"));
const CaseStudies = lazy(() => import("./pages/casestudies/casestudies"));
const OurInfluencers = lazy(() =>
  import("./pages/OurInfluencers/OurInfluencers")
);
const Error = lazy(() => import("./pages/error/404"));

const App = () => {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/about" element={<About />} />
            <Route path="/our-team" element={<Teammembers />} />
            <Route path="/services/designing-branding" element={<Graphic />} />
            <Route path="/services/ui-ux" element={<Uiux />} />
            <Route path="/services/digital-marketing" element={<SmmPlans />} />
            <Route path="/services/web-development" element={<WebPlans />} />
            <Route
              path="/services/legal-compliance"
              element={<LegalCompliancePlans />}
            />
            <Route path="/seocampaign" element={<SEOCampaign />} />
            <Route path="/case-studies" element={<CaseStudies />} />
            <Route path="/our-influencers" element={<OurInfluencers />} />
            <Route path="*" element={<Error />} />
          </Routes>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
